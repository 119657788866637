<template>
<div class="ny_pad">
	<div class="menu_ico">
	 <top_menu></top_menu>
	 </div>
<!--  about_lay dj-->
	 <div class="" id="dj1">
	   <div class="about_wapper">
       <img src="@/assets/images/dj.jpg" />
<!--	    <p class="piclay" v-for="(item, index) in testdata2" :key="index">-->
<!--        <a :href="'/content?id='+item.id">-->
<!--          <img :src="item.images" :alt="item.title">-->
<!--        </a>-->
<!--      </p>-->
<!--	    <dl class="fontlay dj_lay">-->
<!--	     <ul id="myTab1">-->
<!--	      <li class="active" href="#taocan1" data-toggle="tab"><img src="@/assets/images/icon20.png" /></li>-->
<!--	     </ul>-->
<!--	     <dd id="myTabContent1">-->
<!--	      <div class="tab-pane fade active in" id="taocan1">-->
<!--	       <a href="#" v-for="(item, index) in testdata1" :key="index">-->
<!--	        <strong>{{item.title}}</strong>-->
<!--	        {{item.description}}-->
<!--	       </a>-->
<!--	      </div>-->
<!--	      <div class="tab-pane fade" id="taocan2">-->
<!--	      </div>-->
<!--	     </dd>-->
<!--	    </dl>-->



	   </div>
	 </div>

  <div class="container">
        <div class="row pd10"><img src="@/assets/images/dj.gif" /></div>
    <div class="">
      <div class="row">
        <div class="col-lg-6">
          <!-- Corpix Marketing Content Start -->
          <div class="marketing-content swiper hsSwiper4">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in testdata" :key="index">
                <div class="section-title">
                  <a :href="'/content?id='+item.id"><img :src="item.images" :alt="item.title"></a>
                  <div class="title"><a :href="'/content?id='+item.id">{{(item.title||'')}}</a></div>
                </div>
              </div>
            </div>
          </div>
          <!-- Corpix Marketing Content End -->
        </div>
        <div class="col-lg-6">
          <div class="marketing-item-wrap">
            <!-- Marketing Item Start -->
            <div class="marketing-item">

              <div class="marketing-text">


                <dl class="fontlay dj_lay">
                  <dd id="myTabContent1">
                    <div class="tab-pane fade active in" id="taocan1">
                      <a href="#" v-for="(item, index) in testdata1" :key="index">
                        <strong>
                          <a :href="'/content?id='+item.id"> {{(item.publishTime||'').slice(0,10).replace(/-/g,'/')}}&nbsp; {{item.title}}</a>
                        </strong>
                      </a>
                    </div>
                    <div class="tab-pane fade" id="taocan2">
                    </div>
                  </dd>
                </dl>

                </div>
            </div>
            <!-- Marketing Item End -->
          </div>
        </div>
      </div>
      <div>
        <a :href="'/list?catid=61'"><img src="@/assets/images/m.jpg" /></a>
      </div>
    </div>
  </div>


  <div class="container"  id="dj3">
    <div class="row pd10"><img src="@/assets/images/zr.gif" /></div>
    <div class="">
      <div class="row">
        <div class="col-lg-6">
          <!-- Corpix Marketing Content Start -->
          <div class="marketing-content swiper hsSwiper4">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in testdata2" :key="index">
                <div class="section-title">
                  <a :href="'/content?id='+item.id"><img :src="item.images" :alt="item.title"></a>
                  <div class="title"><a :href="'/content?id='+item.id">{{(item.title||'')}}</a></div>
                </div>
              </div>
            </div>
          </div>
          <!-- Corpix Marketing Content End -->
        </div>
        <div class="col-lg-6">
          <div class="marketing-item-wrap">
            <!-- Marketing Item Start -->
            <div class="marketing-item">

              <div class="marketing-text">


                <dl class="fontlay dj_lay">
                  <dd id="myTabContent2">
                    <div class="tab-pane fade active in" id="taocan3">
                      <a href="#" v-for="(item, index) in testdata3" :key="index">
                        <strong>
                          <a :href="'/content?id='+item.id"> {{(item.publishTime||'').slice(0,10).replace(/-/g,'/')}}&nbsp; {{item.title}}</a>
                        </strong>
                      </a>
                    </div>
                    <div class="tab-pane fade" id="taocan4">
                    </div>
                  </dd>
                </dl>



              </div>
            </div>
            <!-- Marketing Item End -->
          </div>
        </div>
      </div>
      <div>
        <a :href="'/list?catid=62'"><img src="@/assets/images/m.jpg" /></a>
      </div>
    </div>
  </div>


<!--  <div class="container">-->
<!--    <div class="row pd10"><img src="@/assets/images/dj.gif" /></div>-->
<!--    <div class="row blog-left-content" v-for="(item, index) in testdata" :key="index">-->


<!--      <div class="content col-lg-8 col-md-12 col-sm-12">-->
<!--        <H3><a :href="'/content?id='+item.id">{{(item.title||'')}}</a></H3>-->
<!--        <p>{{(item.description||'')}}</p>-->
<!--        <a :href="'/content?'+item.id" class="read-btn">Read More</a>-->
<!--      </div>-->

<!--      <div class="blog-right col-lg-2 col-md-12 col-sm-12">-->
<!--        {{(item.publishTime||'').slice(0,10).replaceAll('-','/')}}-->
<!--      </div>-->
<!--    </div>-->

<!--    <div>-->
<!--      <a :href="'/list?catid=61'"><img src="@/assets/images/m.jpg" /></a>-->
<!--    </div>-->
<!--  </div>-->


<!--  <div class="container">-->
<!--    <div class="row"><img src="@/assets/images/zr.gif" /></div>-->
<!--    <div class="row blog-left-content" v-for="(item, index) in testdata1" :key="index">-->
<!--      <div class="content col-lg-8 col-md-12 col-sm-12">-->
<!--        <H3><a :href="'/content?id='+item.id">{{(item.title||'')}}</a></H3>-->
<!--        <p>{{(item.description||'')}}</p>-->
<!--        <a :href="'/content?'+item.id" class="read-btn">Read More</a>-->
<!--      </div>-->

<!--      <div class="blog-right col-lg-2 col-md-12 col-sm-12">-->
<!--        {{(item.publishTime||'').slice(0,10).replaceAll('-','/')}}-->
<!--      </div>-->
<!--    </div>-->

<!--    <div>-->
<!--      <a :href="'/list?catid=62'"><img src="@/assets/images/m.jpg" /></a>-->
<!--    </div>-->
<!--  </div>-->


	 <!-- about_lay -->
<!--	 <dl class="qywh_lay" id="dj2">-->
<!--	  <dt><img src="@/assets/images/icon22.png" /></dt>-->
<!--	  <dd>-->
<!--	    <div><img src="@/assets/images/icon24.png" /><span>辉山致力于乳制品全产业链建设，成为规模化和专业化的优秀乳品企业让每一个中国人每天都能喝上高品质的放心好奶</span></div>-->
<!--	    <div><img src="@/assets/images/icon24_1.png" /><span>食品安全重于泰山，社会责任是成长基石</span></div>-->
<!--	    <div><img src="@/assets/images/icon24_2.png" /><span>不断探索中国乳制品行业全产业链发展模式，打造国人值得信赖的乳品品牌价值观的乳品品牌</span></div>-->
<!--	    <div><img src="@/assets/images/icon24_3.png" /><span>辉山乳业坚定的以正直、诚实的品德为公众提供安全、优质的乳制品-->
<!--	辉山乳业从源头做起，致力于探索中国乳制品行业全产业链发展模式-->
<!--	辉山乳业坚信奉献社会、成就自我，以积极承担社会责任为企业使命-->
<!--	辉山乳业关注员工的成长与发展，为每一位员工提供放手拼搏的舞台-->
<!--	辉山乳业与合作伙伴互惠共赢、共同发展，以实际行动回报投资者-->
<!--	辉山乳业坚持创新与进步，不断超越自己，实现企业专业化发展目标-->
<!--	辉山乳业致力于构建绿色生态型企业，实现企业、人与自然和谐共生</span></div>-->
<!--	    <a target="_blank" href="http://www.huishandairy.com/CN/Web/AboutHS/2015%E5%B9%B4%E7%A4%BE%E4%BC%9A%E8%B4%A3%E4%BB%BB%E6%8A%A5%E5%91%8A.pdf" class="az_btn" id="dj3"><img src="@/assets/images/icon25.png" /><img src="@/assets/images/icon26.png" /></a>-->
<!--	  </dd>-->
<!--	 </dl>-->
	 <!-- qywh_lay -->
	 <fo_oter></fo_oter>
	<!-- footer -->
</div>
</template>

<script>
import top_menu from '../components/menu.vue'
import fo_oter from '../components/footer.vue'
import "@/assets/js/jquery-1.9.1.min.js";
import "@/assets/bootstrap/bootstrap.min.js";
import {GETDATA1,GETDATA2,GETDATA3,GETDATA4} from '../apis/dj.js'
import {GETLISTDATA} from "@/apis/api";
export default {
  name: 'dj',
  components: {
    top_menu,fo_oter
  },
  data() {
    return {
		testdata:[],
		testdata1:[],
		testdata2:[],
		testdata3:[]
    };
  },
  mounted() {
	  this.onloading();
  },
  methods: {
  	  async onloading(){
        GETLISTDATA({catid:61,pageNum:1,pageSize:6}).then((res) => {
          if(res.code == 200){
            if(res.rows.length>0){
              this.testdata[0] = res.rows[0];
            }
            if(res.rows.length>1){
              res.rows.shift();
              this.testdata1 = res.rows;
            }
          }
        });

        GETLISTDATA({catid:62,pageNum:1,pageSize:6}).then((res) => {
          if(res.code == 200){
            if(res.rows.length>0){
              this.testdata2[0]  = res.rows[0];
            }
            if(res.rows.length>1){
              res.rows.shift();
              this.testdata3 = res.rows;
            }
          }
        });

  	  },
  }
}
</script>